import './menu-element/index.js';

type MenuName = 'account-menu' | 'shop-menu';

type MenuOpenRequestedEvent = CustomEvent<{
  /**
   * The name of the menu to open
   *
   * @deprecated use `panel` instead
   */
  name?: MenuName;

  /**
   * The id of a menu panel to open
   *
   * This is used for the new menu system
   */
  panel?: string;
}>;

type MenuClosedEvent = CustomEvent<{
  /**
   * @deprecated use `panel` instead
   */
  name?: MenuName;

  panel?: string;
}>;

type MenuOpenedEvent = CustomEvent<{
  /**
   * @deprecated use `panel` instead
   */
  name?: MenuName;

  panel?: string;
}>;

declare global {
  interface WindowEventMap {
    'menu-close-requested': Event;
    'menu-closed': MenuClosedEvent;
    'menu-open-requested': MenuOpenRequestedEvent;
    'menu-opened': MenuOpenedEvent;
  }
}

interface BodyElement extends HTMLElement {
  readonly dataset: {
    /**
     * The currently open menu
     */
    menuOpen: MenuName;
  };
}

/**
 * This intentionally does not accept a specific menu to close because that gives the callers the
 * ability to specify an incorrect menu and requires that callers know the name. Instead, this
 * closes whatever the currently open menu is, and does nothing when there is no currently open
 * menu.
 */
function menuClose() {
  removeEventListener('shroud-clicked', menuClose);

  // Get the currently open menu, if any.
  const name = <MenuName | null | undefined>document.body.dataset.menuOpen;
  if (!name) {
    return;
  }

  (<BodyElement>document.body).removeAttribute('data-menu-open');

  const sideMenu = document.querySelector('menu-element');

  // We have to check whether the side menu is defined as not all menu close calls are made on a
  // page where the side menu is present in the dom. For example, the money back guarantee pages do
  // not have a side menu, but they do have a side cart, which still uses menu open/close for the
  // purposes of the side cart upsell section.

  // TODO: this does not belong here. now that we emit a menu closed event, this should be moved to
  // some listener that is attached from the appropriate module.

  if (sideMenu) {
    sideMenu.classList.remove('expanded');
    sideMenu.classList.add('collapsed');
    sideMenu.setAttribute('inert', '');

    type Detail = WindowEventMap['modal-close-requested']['detail'];
    const modalCloseEvent = new CustomEvent<Detail>('modal-close-requested',
      { detail: { element: sideMenu } }
    );
    dispatchEvent(modalCloseEvent);
  }

  dispatchEvent(new CustomEvent<MenuClosedEvent['detail']>('menu-closed', {
    detail: {
      name
    }
  }));
}

/**
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 */
function menuOpen(menuName: MenuName) {
  if (menuName === document.body.dataset.menuOpen) {
    console.debug('menu is already open', menuName);
    return;
  }

  (<BodyElement>document.body).dataset.menuOpen = menuName;

  const menuElement = document.querySelector('menu-element');
  menuElement.removeAttribute('inert');
  menuElement.classList.remove('collapsed');
  menuElement.classList.add('expanded');

  // The menu element itself does not scroll. It contains a nested element in the light dom that is
  // scrollable. We cannot reach into the internals of the custom element shadow dom. However, we
  // can trigger a scroll to top as a side effect of setting this data attribute.
  menuElement.dataset.triggerScrollTop = 'true';
  menuElement.dataset.activeMenu = menuName;

  type Detail = WindowEventMap['modal-show-requested']['detail'];
  const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
    { detail: { element: menuElement } });
  dispatchEvent(modalShowEvent);
  addEventListener('shroud-clicked', menuClose);

  const menuOpenedEvent = new CustomEvent<MenuOpenedEvent['detail']>('menu-opened', {
    detail: {
      name: menuName
    }
  });
  dispatchEvent(menuOpenedEvent);
}

function onMenuOpenRequested(event: MenuOpenRequestedEvent) {
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  if (!event.detail.name) {
    // exit early as we don't support the new menu system here
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-deprecated
  menuOpen(event.detail.name);
}

function onMenuCloseRequested(_event: Event) {
  // this is safe to call even if the intent was to close a new menu panel
  menuClose();
}

/**
 * The side menu section is initially hidden. Unhide it on load. This prevents a flash of unstyled
 * content.
 *
 * @todo is the optional chaining here needed? remove it if not needed or explain why it is needed
 * in a comment
 */
function onDOMContentLoaded(_event: Event) {
  const sideMenu = document.getElementById('shopify-section-side-menu');
  sideMenu?.classList.remove('hidden');
}

addEventListener('menu-open-requested', onMenuOpenRequested);
addEventListener('menu-close-requested', onMenuCloseRequested);

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
